import { defineStore } from 'pinia'

export const signupStore = defineStore('signUp', {
  state: () => ({
    csrf: '',
    token: '',
    user_id: '',
    cards: [],
    step: 0,
    phone: '',
    availableFileTypes: [],
    files: {},
    /** @var {
     * undefined |
     *  {
     *   description:null|string,
     *   id: number,
     *   items: {
     *     bgColor: string | null,
     *     description: string | null,
     *     id: number,
     *     name: string,
     *     required: boolean,
     *     tag: string | null,
     *     items: {
     *       id: number,
     *       mask: string | null,
     *       name: string,
     *       relation_field:{
     *         id: number,
     *         column: string,
     *         field_id: number,
     *         relation: string
     *       },
     *       required: boolean,
     *       type: string,
     *       types: {
     *         field_id: number,
     *         hidden: boolean,
     *         id: number,
     *         order: number,
     *          value: string,
     *        }[],
     *      }[]
     *    }[],
     *   type:string
     *  }[]
     * } settings */
    settings: undefined
  })
})
