<template>
  <component
    :is="layoutComponent"
    v-if="layoutComponent"
  >
    <router-view />
  </component>
  <PopupItems />
  <notification-items />
</template>

<script setup>
import { computed, defineAsyncComponent, inject, onMounted, shallowRef, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import PopupItems from '@/views/parts/PopupItems.vue'
import NotificationItems from '@/views/parts/NotificationItems.vue'
import { setCookie } from '@/utils/cookies'

/** @var {DashboardLayer} dashboardLayer */
const dashboardLayer = inject('DashboardLayer')
const route = useRoute()
const router = useRouter()

/** @var {SignupLayer} signupLayer */
const signupLayer = inject('SignupLayer')

const layout = computed(() => {
  return (route.meta?.layout || 'Default') + 'Layout'
})

watch(layout, () => {
  loadLayout()
})

const layoutComponent = shallowRef()

const loadLayout = async () => {
  await router.isReady()
  try{

    layoutComponent.value = defineAsyncComponent(() => import(`./layouts/${layout.value}.vue`).catch((e) => {
      // eslint-disable-next-line no-console
      console.warn(`Component ${layout.value} not found`)
    }))
  }
  catch (e){

  }

  if(route?.query?.click_id){
    localStorage.setItem('guruleads', JSON.stringify(route?.query))
  }
}

watch(layout, () => {
  loadLayout()
})

onMounted(() => {
  loadLayout()

  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('user_id')
  let calculatorData = localStorage.getItem('calculatorData')

  if (token?.length > 10) {
    dashboardLayer.setToken(token)
  }
  if(userId){
    signupLayer.setClientId(userId)
  }
  if(calculatorData){
    try{
      calculatorData = JSON.parse(calculatorData)
      dashboardLayer.setCalculatorData(calculatorData?.sum, calculatorData?.date, calculatorData?.returnSum, calculatorData?.returnDate)
    }
    catch (e){ /* empty */ }
  }

  // Сохраняем в куки
  const mySearchParams = new URLSearchParams(window.location.search)

  for (const [key, value] of mySearchParams.entries()) {
    setCookie(key, value, 30)
  }
})

</script>

<style lang="scss">
@import "@/assets/sass/styles.scss";
</style>
